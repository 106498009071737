<template>
  <div>
    <!--start notFound or error 404 content-->
    <v-container fill-height grid-list-xsm class="mx-auto justify-center">
      <v-card
        tile
        elevation="0"
        color="white"
        fill-height
        height="95vh"
        class="mx-auto text-center justify-center align-center"
      >
        <div class="pt-10 mx-auto justify-center align-center">
          <v-img
            src="@/assets/images/5203299.jpg"
            contain
            class="mb-8 mx-auto justify-center align-center"
            style="width: 350px; height: auto;"
          />
        </div>

        <div class="pb-5 text-center">
          <h1 class="mb-2">
            {{ $t("generic.lang_oops") }}
          </h1>

          <span class="body-2 grey--text">
            {{ $t("generic.lang_not_found_msg") }}
          </span>
        </div>

        <div>
          <v-btn
            elevation="0"
            color="primary"
            class="px-6 text-capitalize rounded-xl"
            @click="$router.push('/')"
          >
            {{ $t("generic.lang_back_to_menu") }}
          </v-btn>
        </div>
      </v-card>
    </v-container>
    <!--end notFound or error 404 content-->
  </div>
</template>

<script>
export default {
  name: "NotFoundComponent",
};
</script>
