<template>
  <div>
    <!--start not-found component-->
    <not-found-component />
    <!--end not-found component-->
  </div>
</template>

<script>
//import components
import NotFoundComponent from "@/components/notFound/NotFoundComponent.vue";

export default {
  name: "NotFound",

  components: { NotFoundComponent },
};
</script>
